import { Result } from "antd-mobile";
import React from "react";

class Success extends React.Component {
    render() {
        return <>
            <Result
                status='error'
                title='未登录'
                description='请先登录，在使用'
            />
        </>;
    }
}

export default Success;