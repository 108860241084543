import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {
    createBrowserRouter,
    RouterProvider,
    Route, redirect,
} from "react-router-dom";
import Loading from "./loading";
import Error from "./error";
import {isLoginFromApp, whetherToReceiveRewardsFromApp} from "./bridge";
import Sucess from "./success";
import Submit from "./submit";
import {SafeArea} from "antd-mobile";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Loading/>,
        loader: async ({request}) => {
            return new Promise(function (resolve, reject) {
                const isLogin: boolean = isLoginFromApp();
                resolve(isLogin);
            }).then(function (isLogin) {
                return redirect("/submit");
                if (!isLogin) {
                    return redirect("/error")
                }
                if (whetherToReceiveRewardsFromApp()) {
                    return redirect("/success")
                }
                return redirect("/submit");
            });
        }
    },
    {
        path: "/error",
        element: <Error/>,
    },
    {
        path: "/success",
        element: <Sucess/>,
    },
    {
        path: "/submit",
        element: <Submit/>,
    },
]);


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <SafeArea position={"top"}/>
        <RouterProvider router={router}/>
        <SafeArea position={"bottom"}/>
    </React.StrictMode>
);
