import React, {FC, RefObject, useState} from "react";
import {
    Button,
    DatePicker,
    DatePickerRef,
    Form, ImageUploadItem,
    Input,
    Selector,
    Dialog, ImageUploader
} from 'antd-mobile'
import dayjs from 'dayjs'
import {submitDataToApp} from "./bridge";

export async function upload(file: File) {
    return {
        url: URL.createObjectURL(file),
    }
}

type FieldType = { name?: string; birthday?: string, phone?: string, rs_birthday?: string, rs_training?: string, up_training?: ImageUploadItem[] }

const Submit: FC = () => {
    const [form] = Form.useForm<FieldType>()
    // const [fileList, setFileList] = useState<ImageUploadItem[]>([])
    const onFinish = (values: any) => {
        submitDataToApp(JSON.stringify(values))
    }

    return <>
        <Form
            layout='horizontal'
            mode='card'
            form={form}
            onFinish={onFinish}
            footer={
                <Button block type='submit' color='primary' size='large'>
                    提交
                </Button>
            }>
            <Form.Header> </Form.Header>
            <Form.Item
                name='name'
                label='孩子姓名'
                rules={[{required: true, message: '孩子姓名不能为空'}]}>
                <Input onChange={console.log} placeholder='请输入孩子姓名'/>
            </Form.Item>
            <Form.Item
                name='birthday'
                label='孩子生日'
                trigger='onConfirm'
                rules={[{required: true, message: '孩子生日不能为空'}]}
                onClick={(e, datePickerRef: RefObject<DatePickerRef>) => {
                    datePickerRef.current?.open()
                }}>
                <DatePicker>
                    {value =>
                        value ? dayjs(value).format('YYYY-MM-DD') : '请选择日期'
                    }
                </DatePicker>
            </Form.Item>
            <Form.Item
                name='phone'
                label='联系方式'
                rules={[{required: true, message: '手机号不能为空'}]}>
                <Input placeholder='请输入手机号'/>
            </Form.Item>
            <Form.Item
                name='rs_birthday'
                label='首次发现弱视时间'
                layout='vertical'
                trigger='onConfirm'
                rules={[{required: true, message: '首次发现弱视时间不能为空'}]}
                onClick={(e, datePickerRef: RefObject<DatePickerRef>) => {
                    datePickerRef.current?.open()
                }}>
                <DatePicker>
                    {value =>
                        value ? dayjs(value).format('YYYY-MM-DD') : '请选择日期'
                    }
                </DatePicker>
            </Form.Item>
            <Form.Item
                name='rs_training'
                label='之前是否做过弱视训练'
                layout='vertical'
                childElementPosition='normal'
                rules={[{required: true, message: '请选择'}]}
            >
                <Selector options={[
                    {
                        label: '做过弱视训练',
                        value: '1',
                    },
                    {
                        label: '没做过弱视训练',
                        value: '2',
                    }]}
                          onChange={(arr, extend) => console.log(arr, extend.items)}/>
            </Form.Item>
            <Form.Item
                name='up_training'
                label='上传弱视病例：（一次最多上传6张病）'
                childElementPosition='normal'
                layout='vertical'
                rules={[{required: true, message: '请上传弱视病历'}]}
            >
                <ImageUploader
                    // value={fileList}
                    // onChange={setFileList}
                    upload={upload}
                    maxCount={6}
                    onDelete={() => {
                        return Dialog.confirm({
                            content: '是否确认删除',
                        })
                    }}
                />
            </Form.Item>
        </Form>
    </>;
}

export default Submit;